<template>
  <div>
    <b-modal id="alarmPopup" ref="alarmPopup" cancel-variant="outline-secondary" ok-title="닫기" centered button-size="sm"
      title="팝업 TITLE" size="lg" hideHeaderClose ok-only no-close-on-backdrop no-close-on-esc footer-class="padding-custom">
      <b-row class="match-height">
        <b-col lg="6">
          <b-card  class="mb-0">
            <b-embed id="alramVideo" type="iframe" aspect="16by9" :src="demoVideoSrc"
              allowfullscreen />
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card no-body class="p-1 card-transaction mb-0">
            <b-row class="p-1 justify-content-center">
              <b-badge pill variant="dark" class="badge-glow">{{demoBound}} - {{ $t("label.L0087") }}</b-badge>
              <b-badge pill variant="dark" class="badge-glow ml-1">{{ $t("label.L0083") }}</b-badge>
              <b-badge pill variant="dark" class="badge-glow ml-1">{{demoEventTime}} {{ $t("label.L0045") }}</b-badge>
            </b-row>
            <b-row>
              <b-card-body>
                <div v-if="alarm_obj.length > 0 && alarm_obj[0].objalarmList && alarm_obj[0].objalarmList.length">
                  <div v-for="(event, index) in alarm_obj[0].objalarmList"
                          :key="index" class="transaction-item">
                    <!-- <b-media no-body>
                      <b-media-aside>
                        <b-avatar rounded size="42" :variant="transaction.avatarVariant">
                          <feather-icon size="18" :icon="transaction.avatar" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="transaction-title">
                          {{ transaction.mode }}
                        </h6>
                        <small>{{ transaction.types }}</small>
                      </b-media-body>
                    </b-media>
                    <div class="font-weight-bolder" :class="transaction.deduction ? 'text-danger' : 'text-success'">
                      {{ transaction.payment }}
                    </div> -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar rounded size="32">
                          <div v-if="event.tech==='R'">
                            <img style="width:100%" src="resources/img/icons/dashboard/effect/Camera_4_black.svg"/>
                          </div>
                          <div v-if="event.tech==='S'">
                            <img style="width:100%" src="resources/img/icons/dashboard/effect/Microphone_2_black.svg"/>
                          </div>
                          <div v-if="event.tech==='C'">
                            <img style="width:100%" src="resources/img/icons/dashboard/cctv/n_5_on_black.svg"/>
                          </div>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="transaction-title">
                          {{ event.d_event_type_nm }}
                        </h6>
                      </b-media-body>
                        {{ event.position }}
                      <!-- <div class="font-weight-bolder" :class="transaction.deduction ? 'text-danger' : 'text-success'">
                        {{ event.position }}
                      </div> -->
                    </b-media>
                  </div>
                </div> 
              </b-card-body>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  <audio :src="sound_url" muted hidden id="audio"></audio>
  </div>
</template>

<script>
import {
  BEmbed,
  BCard,
  BCardBody,
  BBadge,
  BButton,
  BModal,
  BCol,
  BRow,
  BAvatar,
  BMedia,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";
import EventBus from "@/event-bus";

export default {
  name: "Alarm",
  components: {
    BEmbed,
    BCard,
    BCardBody,
    BBadge,
    BButton,
    BModal,
    BCol,
    BRow,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      transactionData: [
        {
          mode: 'Wallet',
          types: 'Starbucks',
          avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          payment: '74',
          deduction: true,
        },
        {
          mode: 'Bank Transfer',
          types: 'Add Money',
          avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          payment: '480',
          deduction: false,
        },
        {
          mode: 'Paypal',
          types: 'Add Money',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-danger',
          payment: '480',
          deduction: false,
        },

      ],
      alarm_obj: [],
      eventInfoList: [],
      alarmList: [],
      objalarmList: [],
      current_time: 0,
      file_url: "",
      file_name: "",
      sound_file_url: "",
      sound_file_name: "",
      alarmTimer: null,
      sync: false,
      scheduleList: [],
      scheduleTimer: null,
      equipList: [],
      selectTunnelCode: null,
      selectTunnelInfo: [],
      selectTunnelName: null,
      event_type: {
        1: this.$t("label.L0027"),
        2: this.$t("label.L0022"),
        3: this.$t("label.L0023"),
        4: this.$t("label.L0025"),
        6: this.$t("label.L0024"),
        9: this.$t("label.L0026"),
        11: this.$t("label.L0091"),
        12: this.$t("label.L0092"),
        13: this.$t("label.L0093"),
        14: this.$t("label.L0094"),
      },
      sound_url: "",
      audioTag: null,
      alarmTargetNo: 0,
      player: null,
      alramalias: "",
      mediaServerInfo: {
        ip: null,
        port: null,
      },
      eventPopupTimer: this.$store.getters["verticalMenu/adminUser"].eventPopupTimer,
      autoChangeEventList: null,
      popupCancleEventList: null,
      demoVideoSrc: null,
      demoBound: null,
      demoEventType: null,
      demoEventTime: null,
    };
  },
  methods: {
        objLength: function () {
      return this.alarm_obj.length > 0 ? true : false;
    },
    setIntervalT: function (no) {
      this.sync = true;
      // 알람 팝업 지속 여부 ( true 인 경우 팝업 없어지지 않음 )
      const eventPopupKeepYn = this.$store.getters["verticalMenu/eventPopupKeepYn"];

      // 돌발팝업 지속 여부 판별
      if (!eventPopupKeepYn) {
        // 사용자가 직접 시간 설정했을 경우 실행
        const timer = this.$store.getters["verticalMenu/adminUser"].eventPopupTimer;
        setTimeout(() => {
          this.nextData(no);
          this.$refs.alarmPopup.hide();
        }, timer * 1000);
      }
    },
    nextData: async function (no) {
      this.sync = false;
      for (let i in this.alarm_obj) {
        if (this.alarm_obj[i].alarmTargetNo == no) {
          this.alarm_obj[i].check = false;
        }
      }
      const filteredArr = this.alarm_obj.filter(
        (item) => item.alarmTargetNo !== no
      );

      // splice() 메서드를 사용하여 기존 배열을 변경
      this.alarm_obj.splice(0, this.alarm_obj.length, ...filteredArr);
    },
    closebutton: function () {
      // 알람창 끄기 (확인버튼)
      window.URL.revokeObjectURL(this.sound_url);
      this.alarm_obj.shift();
      this.$emit("open-modal", false);
    },
    alarmClickEvent: function () {
      this.nextData();
    },
    convertDateFormat(dateStr) {
      var year = dateStr.substring(0, 4);
      var month = dateStr.substring(4, 6);
      var day = dateStr.substring(6, 8);
      var hour = dateStr.substring(8, 10);
      var minute = dateStr.substring(10, 12);
      var second = dateStr.substring(12, 14);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
      );
    },
    async getNewEventInfoList(data) {
      let me = this;
      var startPosition = parseFloat(data.Position) - 0.05;
      var endPosition = parseFloat(data.Position) + 0.05;

      // 돌발 DB insert
      me.api.addDemoEventData({
        occurTime: data.OccurTime,
        cameraId: data.CameraID,
        dEventType: data.DEventType,
        position: data.Position,
      });

      try {
        const res = await me.api.selectNewEventInfoList({
          camera_id: data.CameraID,
          start_position: startPosition,
          end_position: endPosition,
          occur_time: data.OccurTime,
        });

        let event_info_list = res.data.eventInfoList;

        if(event_info_list.length==0){
          var demo_tech_nm = null;
          if(data.tech == "R"){
            demo_tech_nm = this.$t("label.L0029");
          } else if(data.tech == "C"){
            demo_tech_nm = this.$t("label.L0031");
          } else if(data.tech == "S"){
            demo_tech_nm = this.$t("label.L0030");
          }
          var demo_event_info_list = [{
            alramalias:data.CameraID,
            bound:data.bound,
            camera_id:data.CameraID,
            d_event_type:data.DEventType,
            link_code:data.LinkCode,
            occur_time:data.OccurTime,
            occur_time_string:me.convertDateFormat(data.OccurTime),
            position:data.Position,
            tech:data.tech,
            tech_nm:demo_tech_nm,
          }];
        }

        me.checkMix2(data, event_info_list.length==0 ? demo_event_info_list : event_info_list);
      } catch (err) {}
    },
    checkMix2: function (data, eventInfoList) {
      let me = this;
      EventBus.$emit("audioSoundSetting", data.DEventType); // 음성 재생

      //돌발유형 타입 담기
      this.eventTypeSetting = data.DEventType;
      // data : 새 돌발 이벤트
      // eventInfoList : 새 돌발 이벤트 기준의 리스트 (from DB)
      // me.alarm_obj : 알림창
      // alarmList : 알림창의 리스트
      // 기존 alarmList 정보에서 {{ $t("label.L0043") }}<로직을 루프를 돌아서 확인
      // let me = this;
      // 기존인지 아닌지에 대한 리턴
      var existFlag = false;
      // linkCode에 현재 선택된 터널코드가 포함되어 있는 경우에만 돌발알람 로직 실행
      // 자동전환 이벤트 발생 시 화면전환, 카메라전환
      var tunnelCode = data.CameraID.substr(0, 3);

      if (
        this.autoChangeEventList.includes(data.DEventType) &&
        this.selectTunnelCode != tunnelCode
      ) {
        EventBus.$emit("eventTunnelMove", tunnelCode, true);
      }

      if (data.CameraID.includes(this.selectTunnelCode)) {
        //첫 돌발이벤트
        if (me.alarm_obj.length == 0) {
          data.alramalias = eventInfoList[0].alramalias;
          me.addAlarm2(data, eventInfoList);
        } else {
          if (eventInfoList !== null) {

            for (let i = 0; i < me.alarm_obj.length; i++) {
              // 기존 알람리스트의 bound, position 과 새 돌발이벤트의 bound, position 비교
              // position +- 100m 범위안이면 같은 이벤트로 봄
              // 영상 분석기인 경우 발생 위치(position),영상 정보 없음
              if (
                me.alarm_obj[i].bound == data.bound &&
                parseFloat(
                  (parseFloat(me.alarm_obj[i].position) - 0.05).toFixed(2)
                ) <= parseFloat(data.Position) &&
                parseFloat(
                  (parseFloat(me.alarm_obj[i].position) + 0.05).toFixed(2)
                ) >= parseFloat(data.Position)
              ) {
                if (
                  new Date(me.convertDateFormat(eventInfoList[0].occur_time)) -
                    new Date(me.convertDateFormat(me.alarm_obj[i].occur_time)) <
                  300000
                ) {
                  // 5분이내 -> 같은 이벤트로 판단 -> 리스트 업데이트
                  //me.alarm_obj[i].occur_time = me.data.OccurTime,
                  //me.alarm_obj[i].occur_time_string = me.convertDateFormat2(data.OccurTime),
                  var arrr = me.addAlarmList(eventInfoList);
                  arrr.forEach((each) => {
                    me.alarm_obj[i].objalarmList.push(each);
                  });
                  existFlag = true;
                  break;
                }
              }
            }
          }
          //기존 알람이 아닐경우
          if (!existFlag) {
            //새 돌발 이벤트로 판단
            for (var i = 0; i < eventInfoList.length; i++) {
              if (data.no == eventInfoList[i].no) {
                data.alramalias = eventInfoList[i].alramalias;
              }
            }
            me.addAlarm2(data, eventInfoList);
          }
          this.$forceUpdate();
        }

        // 대시보드 돌발 circle 표시
        EventBus.$emit(
          "occurEvent",
          data,
          tunnelCode,
          this.popupCancleEventList
        );

        if (this.popupCancleEventList.includes(data.DEventType)) {
          // 알람 표출 안함
          return;
        }

        // 팝업창 열기.
        me.$refs.alarmPopup.show();
        // me.$emit("open-modal", true);
      }
    },
    activeScheduleTimer: function () {
      var date = new Date();
      var next = new Date();

      next.setHours(next.getHours() + 1, 0);
      next.setSeconds(0, 10);

      var time = next - date;

      this.scheduleTimer = setTimeout(() => {
        this.getActvieScheduleList();
        this.activeScheduleTimer();
      }, time);
    },
    async getActvieScheduleList() {
      var vue = this;
      try {
        const res = await this.api.getActiveSchedule({
          tunnelCode: this.selectTunnelCode,
        });
        const list = Array.from(res.data.data.scheduleList);
        vue.scheduleList = list.filter((schedule) => {
          // 공사일정 일자 표시 범위 설정 구간
          const date = new Date();

          // 공사일정 일자 범위 설정
          const startDate = new Date(
            schedule.startDate + " " + schedule.startTime
          );
          const endDate = new Date(schedule.endDate + " " + schedule.endTime);

          return startDate <= date && endDate >= date;
        });
      } catch (err) {}
    },
    async getEquipList() {
      var vue = this;
      try {
        const response = await this.api.selectEquipDashboardList();
        const { data } = response.data;
        vue.equipList = data.equipList;
      } catch (err) {
        console.log("getEquipList error : ", err);
      }
    },
    addAlarm2: function (data, eventInfoList) {
      var me = this;
      var idx = me.alarm_obj.length;
      me.alarmTargetNo++;
      me.setIntervalT(me.alarmTargetNo);
      var tech_nm = null;
      if(data.tech == "R"){
        tech_nm = this.$t("label.L0029");
      } else if(data.tech == "C"){
        tech_nm = this.$t("label.L0031");
      } else if(data.tech == "S"){
        tech_nm = this.$t("label.L0030");
      }
      me.alarm_obj.push({
        no: data.no,
        camera_id: data.CameraID,
        occur_time: data.OccurTime,
        occur_time_string: me.convertDateFormat(data.OccurTime),
        tech: data.tech,
        tech_nm: tech_nm,
        DEventType: data.DEventType,
        d_event_type_nm: me.event_type[data.DEventType],
        link_code_nm: data.bound == 1 ? this.$t("label.L0019") : this.$t("label.L0020"), // direction 1:상행 0:하행
        bound: data.bound,
        position: data.Position,
        rec_video_url: data.RecVideoUrl,
        alramalias: data.alramalias,
        elevation: data.Elevation,
        alarmTargetNo: me.alarmTargetNo,
        check: true,
      });
console.log("me.event_type[data.DEventType]:::",me.event_type[data.DEventType]);
      me.demoBound = data.bound == 1 ? this.$t("label.L0019") : this.$t("label.L0020"), // direction 1:상행 0:하행
      me.demoEventType = me.event_type[data.DEventType],
      me.demoEventTime = me.convertDateFormat(data.OccurTime)

      // 각 알람의 리스트 {{ $t("label.L0043") }}<
      me.alarm_obj[idx].objalarmList = me.addAlarmList(eventInfoList);
console.log("me.alarm_obj[idx].objalarmList:::",me.alarm_obj[idx].objalarmList);
      if (idx == 0) {
        me.eventShowVideo();
      }
    },
    addAlarmList: function (eventInfoList) {
      var me = this;
      me.alarmList = []; // 리

      for (let j = 0; j < eventInfoList.length; j++) {
        let newAlarm = eventInfoList[j];
        var tech_nm = null;
        if(newAlarm.tech == "R"){
          tech_nm = this.$t("label.L0029");
        } else if(newAlarm.tech == "C"){
          tech_nm = this.$t("label.L0031");
        } else if(newAlarm.tech == "S"){
          tech_nm = this.$t("label.L0030");
        }
        me.alarmList.push({
          no: newAlarm.no,
          occur_time: newAlarm.occur_time,
          occur_time_string: newAlarm.occur_time_string,
          tech: newAlarm.tech,
          // tech_nm: newAlarm.tech_nm,
          tech_nm: tech_nm,
          d_event_type_nm: me.event_type[newAlarm.d_event_type],
          videoUrl: newAlarm.rec_video_url,
          // link_code_nm: me.selectTunnelInfo[newAlarm.bound].direction,
          link_code_nm: newAlarm.bound == 1 ? this.$t("label.L0019") : this.$t("label.L0020"), // direction 1:상행 0:하행
          bound: newAlarm.bound,
          position: newAlarm.position.toFixed(2) * 1000 + "m",
          elevation: newAlarm.Elevation,
        });
      }
      return me.alarmList;
    },
    async eventShowVideo() {
      var me = this;
      if (me.alarm_obj.length == 0) return;

      var event = me.alarm_obj[0];
      try {
        var demoEventType = event.DEventType;
        // 데모버전
        me.$nextTick(() => {
          me.showDemoCctv(demoEventType);
        });
      } catch (err) {
        console.log("🚀 ~ eventShowVideo ~ err:", err);
        var videoFrame = document.getElementById("eventModalVideoFrame");
        videoFrame.style.background = "black";
      }
    },
    showDemoCctv: function (demoEventType) {
      this.demoVideoSrc = require("@/assets/video/event/"+demoEventType +".mp4");
    },
  },
  props: {
  },
  mounted() {
    var me = this;
    me.audioTag = document.getElementById("audio");
    EventBus.$on("autoChangeEventList", (autoChangeEventList) => {
      me.autoChangeEventList = autoChangeEventList;
    });
    EventBus.$on("popupCancleEventList", (popupCancleEventList) => {
      me.popupCancleEventList = popupCancleEventList;
    });


  },
  created() {
    var me = this;

    EventBus.$on("selectTunnelName", (val) => {
      me.selectTunnelName = val;
    });
    EventBus.$on("selectTunnelCode", (val) => {
      me.selectTunnelCode = val;
      me.getActvieScheduleList();
    });
    // state.vue에서 현재 선택된 터널 정보를 받아옴
    EventBus.$on("selectTunnelInfo", (val) => {
      me.selectTunnelInfo = val;
    });
    me.activeScheduleTimer();
    me.getEquipList();

    // 돌발 발생시 실행
    me.socket.addListener(me, "demoEvent", function (data) {
      me.getNewEventInfoList(data);
    });

  },
  beforeDestroy() { },
  destroyed() { },
};
</script>
<style lang="scss">
.padding-custom {
    padding: 0.3rem 1.4rem !important;
}
</style>
